import { useDrag, useDrop } from 'react-dnd';
import { dragItemTypes } from '../constant/Common.constant';
import { DragItem } from '../model/Common.model';

export const useDragAndDrop = (
  id: string,
  index: number,
  moveItem: (dragIndex: number, hoverIndex: number) => void,
) => {
  const [{ isDragging }, drag] = useDrag({
    type: dragItemTypes.LOCATION_ITEM,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: dragItemTypes.LOCATION_ITEM,
    hover: (draggedItem: DragItem) => {
      if (!draggedItem) return;

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;
      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  return {
    isDragging,
    dragRef: (node: HTMLElement | null) => drag(drop(node)),
  };
};
