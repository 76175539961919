import { Locale } from './locale.config';

const editTemplatePageLocaleConfig: Locale[] = [
  {
    en: 'Activity Template Name',
    id: 'Nama Templat Pengiriman',
  },
  {
    en: 'Description',
    id: 'Keterangan',
  },
  {
    en: 'Activity Type',
    id: 'Tipe Aktivitas',
  },
  {
    en: 'Standby',
    id: 'Standby',
  },
  {
    en: 'Pickup',
    id: 'Pickup',
  },
  {
    en: 'Dropoff',
    id: 'Dropoff',
  },
  {
    en: 'Standby Location',
    id: 'Lokasi Standby',
  },
  {
    en: 'Edit Activity Template',
    id: 'Ubah Templat Pengiriman',
  },
  {
    en: 'Activity template name is required.',
    id: 'Nama Templat Pengiriman wajib diisi.',
  },
  {
    en: 'You can only use Activity Location with the type of Shipper that owned by the Shipper you chose here. You can also make the Activity Template type public by leaving this field blank.',
    id: 'Anda hanya dapat menggunakan Lokasi Perjalanan dengan jenis Pengirim yang dimiliki oleh Pengirim yang Anda pilih di sini. Anda juga dapat membuat jenis Templat Pengiriman publik dengan mengosongkan bidang ini.',
  },
  {
    en: 'Location is required.',
    id: 'Lokasi wajib diisi.',
  },
  {
    en: 'Activity template successfully updated',
    id: 'Templat Pengiriman berhasil diubah',
  },
  {
    en: "Selected shipper doesn't have Activity Template",
    id: 'Pengirim yang dipilih belum memiliki Template Pengiriman',
  },
  {
    en: 'Clear search',
    id: 'Hapus pencarian',
  },
  {
    en: 'Edit Activity Template',
    id: 'Ubah Templat Pengiriman',
  },
];

export default editTemplatePageLocaleConfig;
