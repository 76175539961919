import styled from '@emotion/styled';
import React from 'react';
import tw from 'twin.macro';
import { useDragAndDrop } from '../../../hook/useDragAndDrop.hook';
import { ActivityTemplateItem } from '../../../model/ActivityTemplate.model';
import { Text } from '../../atom';
import { Drag } from '../../atom/Icon/Icon.atom';

type Props = {
  id: string;
  index: number;
  item: ActivityTemplateItem;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
};

const SectionLocation = styled.div(
  ({ isDragging }: { isDragging?: boolean }) => [
    tw`flex gap-2 items-center mb-2 py-1 px-2 max-w-full rounded-t-md border border-b-2 border-beige-lines border-b-grey-two bg-white`,
    isDragging && tw`opacity-50`,
    !isDragging && tw`cursor-move`,
  ],
);

export default function ActivityTemplateDraggableItem({
  id,
  index,
  item,
  moveItem,
}: Props) {
  const { isDragging, dragRef } = useDragAndDrop(id, index, moveItem);

  return (
    <div ref={dragRef} tw="max-w-[calc(100% - 2rem)]">
      <SectionLocation isDragging={isDragging}>
        <Drag width={8} iconStyle={tw`-mt-0.5`} />
        <Text.Label tw="max-w-full truncate">{`${index + 1}. ${
          item.location.name
        }`}</Text.Label>
      </SectionLocation>
    </div>
  );
}
