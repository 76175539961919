import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ErrorCodes } from '../constant';
import { ApiErrorResponse } from '../service/api.endpoint';

/**
 * get error messages based on code provided,
 * @param code
 * @returns string
 */
export const errorCodeToLabel = (code = ''): string => {
  const codes: Record<ErrorCodes | string, string> = {
    [ErrorCodes.ORGANIZATION_NOT_FOUND]:
      "Either your organization is not yet registered on this server or you don't have permission to access this service.",
    [ErrorCodes.AUTH_TOKEN_ALREADY_CONSUMED]:
      'Authentication token already expired',
    [ErrorCodes.AUTH_INVALID_CREDENTIALS]: 'Invalid Credentials',
    [ErrorCodes.SSO_CHECK_AUTH_FAILED]: 'Please log in again to continue',
    [ErrorCodes.SSO_VERIFY_AUTH_FAILED]:
      'Unable to validate current session, please try again.',
    [ErrorCodes.SSO_REFRESH_TOKEN_FAILED]: 'Session already expired',
    [ErrorCodes.SSO_FORBIDDEN_REQUEST]:
      "Forbidden request, You don't have permission to access this service",
    [ErrorCodes.DRIVER_ALREADY_ASSIGNED_TO_DRIVING_CONTEST]:
      'Driver already assigned to Driver Contest.',
    [ErrorCodes.DRIVER_DRIVING_CONTEST_NOT_YET_ASSIGNED]:
      'Driver not yet assigned to Driver Contest.',
    [ErrorCodes.DRIVER_PHONE_NUMBER_TAKEN]:
      'Driver phone number already taken.',
    [ErrorCodes.DRIVER_PHONE_NUMBER_TAKEN]:
      'Driver phone number already taken.',
    [ErrorCodes.DRIVER_NOT_FOUND]: 'Driver not found',
    [ErrorCodes.DRIVER_VEHICLE_ALREADY_ASSIGNED]:
      'Driver vehicle already assigned',
    [ErrorCodes.DRIVER_ALREADY_DEACTIVATED]: 'Driver already deactivated',
    [ErrorCodes.DRIVER_ALREADY_ACTIVE]: 'Driver already active',
    [ErrorCodes.DRIVER_ALREADY_ON_DUTY]: 'Driver already on duty',
    [ErrorCodes.DRIVER_HAS_ACTIVE_ASSIGNMENT]: 'Driver has active schedules',
    [ErrorCodes.DRIVER_HAS_FUTURE_ASSIGNMENT]:
      'Driver has active schedules in the future',
    [ErrorCodes.DRIVER_INVALID_PIN_FORMAT]: 'Driver invalid pin format',
    [ErrorCodes.DRIVER_TIMESHEET_OVERLAPPING]:
      'Selected time already registered. Please choose another delivery time.',
    [ErrorCodes.DRIVER_TIMESHEET_DETAIL_NOT_FOUND]:
      'Timesheet detail not found',
    [ErrorCodes.SHIPPER_PHONE_NUMBER_TAKEN]:
      'Shipper phone number already taken.',
    [ErrorCodes.SHIPPER_NAME_TAKEN]: 'Shipper name already taken.',
    [ErrorCodes.SHIPPER_NOT_FOUND]: 'Shipper not found.',
    [ErrorCodes.SHIPPER_ALREADY_DEACTIVATED]: 'Shipper already deactivated',
    [ErrorCodes.SHIPPER_TOTAL_REVENUE_NOT_CALCULATED]:
      'Total shipper revenue not calculated',
    [ErrorCodes.DRIVER_ALREADY_ASSIGNED_TO_DRIVING_CONTEST]:
      'Driver already assigned to driver contest',
    [ErrorCodes.SO_NUMBER_TAKEN]:
      'Shipper order number is already taken, please enter another.',
    [ErrorCodes.SO_ORDER_NUMBER_TAKEN]:
      'Shipper order number is already taken, please enter another.',
    [ErrorCodes.SO_REFERENCE_NUMBER_TAKEN]:
      'Reference number is already taken, please enter another.',
    [ErrorCodes.SO_TRACKING_CODE_TAKEN]:
      'Tracking number is already taken, please enter another.',
    [ErrorCodes.SO_PAYMENT_STATUS_ALREADY_PAID]:
      'Shipper Order payment already paid',
    [ErrorCodes.SO_PAYMENT_AMOUNT_LIMIT_EXCEEDED]:
      'Shipper Order payment amount limit exceeded',
    [ErrorCodes.SO_PAYMENT_NOT_FOUND]: 'Shipper Order payment not found',
    [ErrorCodes.REQUEST_INVALID_ARGUMENT]: 'Invalid arguments',
    [ErrorCodes.LOCATION_NAME_TAKEN]: 'Location name is already taken.',
    [ErrorCodes.LOCATION_NOT_FOUND]: 'Location not found',
    [ErrorCodes.LOCATION_EXISTS_IN_ACTIVE_SO]:
      'Location exists in active Shipper Order',
    [ErrorCodes.LOCATION_EXISTS_IN_ACTIVE_HO]:
      'Location exists in active Hauling Order',
    [ErrorCodes.LOCATION_EXISTS_IN_ACTIVITY_TEMPLATE]:
      'Location exists in activity template',
    [ErrorCodes.ACTIVITY_TEMPLATE_NAME_TAKEN]: 'Template name already taken.',
    [ErrorCodes.JO_NOT_FOUND]: 'Job order not found.',
    [ErrorCodes.JO_NUMBER_FORMAT_NOT_ALLOWED]:
      'Job order number format is not allowed.',
    [ErrorCodes.JO_NUMBER_TAKEN]: 'Job order number already taken.',
    [ErrorCodes.JO_SO_NOT_AVAILABLE]:
      'Shipper Order has been used in a Job Order.',
    [ErrorCodes.JO_SO_NOT_FOUND]:
      'Shipper Order not found or have been used in a Job Order.',
    [ErrorCodes.JO_VEHICLE_NOT_FOUND]: 'Vehicle not found.',
    [ErrorCodes.JO_DRIVER_NOT_FOUND]: 'Driver not found.',
    [ErrorCodes.JO_ALREADY_DELIVERING]: 'Job Order already delivering.',
    [ErrorCodes.JO_DRIVER_MISS_MATCH]: 'Assigned driver already changed.',
    [ErrorCodes.JO_EMPTY]: 'Unable to download an empty table',
    [ErrorCodes.JO_OPERATION_NOT_PERMITTED]:
      "You're not allowed do this process",
    [ErrorCodes.JO_DELIVERY_ALREADY_COMPLETED]: 'Delivery already completed',
    [ErrorCodes.JO_DELIVERY_ACTIVITY_ALREADY_COMPLETED]:
      'Activity already completed',
    [ErrorCodes.JO_EXPENSE_OPERATION_NOT_PERMITTED]:
      'This operation cannot be performed for approved/rejected expense',
    [ErrorCodes.SO_EMPTY]: 'Unable to download an empty table',
    [ErrorCodes.HO_EMPTY]: 'Unable to download an empty table',
    [ErrorCodes.SHIPPER_EMPTY]: 'Unable to download an empty table',
    [ErrorCodes.DRIVER_EMPTY]: 'Unable to download an empty table',
    [ErrorCodes.LOCATION_EMPTY]: 'Unable to download an empty table',
    [ErrorCodes.SCHEDULE_NOT_FOUND]: 'Schedule not found',
    [ErrorCodes.AUTH_TOKEN_EXPIRED]: 'Token Expired',
    [ErrorCodes.SO_ALREADY_TRANSITING]: 'Shipper Order already transiting.',
    [ErrorCodes.SO_ALREADY_ASSIGNED]: 'Shipper Order already assigned.',
    [ErrorCodes.SO_ALREADY_IN_TRANSIT]: 'Shipper Order already in transit.',
    [ErrorCodes.SO_ALREADY_STARTED]: 'Shipper Order already started.',
    [ErrorCodes.SO_ALREADY_RESERVED]: 'Shipper Order already reserved.',
    [ErrorCodes.SO_ALREADY_DELIVERED]: 'Shipper Order already delivered.',
    [ErrorCodes.SO_ALREADY_PAID]: 'Shipper Order already paid',
    [ErrorCodes.SO_GOODS_LENGTH_ERROR]:
      'Submitted goods is not equal to the data in the database',
    [ErrorCodes.SO_GOODS_TYPE_ERROR]:
      'Only dropoff activity is allowed to be updated',
    [ErrorCodes.SO_GOODS_NOT_FOUND]: 'Activity goods is not found',
    [ErrorCodes.SO_GOODS_DEADLINE_EXCEEDED]:
      'Unable to update the goods more than 72 hours on delivered shipper order',
    [ErrorCodes.SO_OPERATION_NOT_PERMITTED]:
      "You're not allowed do this process",
    [ErrorCodes.SO_ALREADY_DELETED]: 'Shipper Order already deleted',
    [ErrorCodes.REPORT_SHIPPER_ORDER_DATA_NOT_EXISTS]:
      'Unable to download an empty table',
    [ErrorCodes.REPORT_JOB_ORDER_DATA_NOT_EXISTS]:
      'Unable to download an empty table',
    [ErrorCodes.REPORT_SHIPPER_REVENUE_DATA_NOT_EXISTS]:
      'Unable to download an empty table',
    [ErrorCodes.HO_ORDER_NUMBER_TAKEN]: 'Hauling Order number already taken',
    [ErrorCodes.HO_REFERENCE_NUMBER_TAKEN]:
      'Hauling Order reference number already taken',
    [ErrorCodes.HO_NUMBER_FORMAT_NOT_ALLOWED]:
      'Hauling Order number format is not allowed',
    [ErrorCodes.HO_SHIPPER_LOCATION_MISS_MATCH]:
      'Hauling Order shipper location miss match',
    [ErrorCodes.HO_NOT_YET_ASSIGNED]: 'Hauling Order not yet assigned',
    [ErrorCodes.HO_ALREADY_ASSIGNED]: 'Hauling Order already assigned',
    [ErrorCodes.HO_ALREADY_STARTED]: 'Hauling Order already started',
    [ErrorCodes.HJO_ALREADY_DELIVERED]: 'Job Order already delivered.',
    [ErrorCodes.HO_ALREADY_DELIVERED]: 'Hauling Order already delivered',
    [ErrorCodes.HO_NUMBER_TAKEN]:
      'Hauling order number is already taken, please enter another.',
    [ErrorCodes.SO_NOT_FOUND]: 'Tracking information is not available',
    [ErrorCodes.ORGANIZATION_IN_RESETTING]: 'Reset is Resetting',
    DEFAULT: 'Unknown error has occured',
  };

  return codes[code] || codes.DEFAULT;
};

export const getAuthErrorTitle = (code?: string): string => {
  const error: Record<ErrorCodes | string, string> = {
    // When user verified by SSO but in JO system the organization not yet registered
    [ErrorCodes.ORGANIZATION_NOT_FOUND]: 'Organization is not found!',
    [ErrorCodes.SSO_CHECK_AUTH_FAILED]: 'Your SSO session has expired!',
    [ErrorCodes.AUTH_TOKEN_EXPIRED]: 'Your Auth session has expired!',
    [ErrorCodes.SSO_REFRESH_TOKEN_FAILED]: 'Your Refresh session has expired!',
    [ErrorCodes.SSO_VISA_CREDENTIAL_FAILED]: 'Your Visa session has expired!',
    DEFAULT: 'Authentication Error!',
  };

  return code && error[code] ? error[code] : error.DEFAULT;
};

export const getBaseErrorCode = (response: unknown) => {
  const { error } = response as { error: Record<string, unknown> };
  const errorData = (error as FetchBaseQueryError).data;

  // Error data is null when the captured error is come from ErrorBoundary
  if (!errorData) return ErrorCodes.ERROR_CODE_NOT_AVAILABLE;

  return (errorData as ApiErrorResponse)?.error?.code || '';
};
