import React from 'react';
import { Params } from 'react-router-dom';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { activityTemplateRoute } from '../../view/ActivityTemplate/activityTemplate.route';
import { activityTemplateAddRoute } from '../../view/ActivityTemplateAdd/ActivityTemplateAdd.route';
import { activityTemplateDeleteRoute } from '../../view/ActivityTemplateDelete/activityTemplateDelete.route';
import { activityTemplateEditRoute } from '../../view/ActivityTemplateEdit/ActivityTemplateEdit.route';
import { completeActivityRoute } from '../../view/CompleteActivity/CompleteActivity.route';
import { completeActivityConfirmRoute } from '../../view/CompleteActivityConfim/CompleteActivityConfirm.route';
import { deliveryLocationRoute } from '../../view/DeliveryLocation/deliveryLocation.route';
import { deliveryLocationAddRoute } from '../../view/DeliveryLocationAdd/DeliveryLocationAdd.route';
import { deliveryLocationAddBulkRoute } from '../../view/DeliveryLocationAddBulk/DeliveryLocationAddBulk.route';
import { deliveryLocationDeleteRoute } from '../../view/DeliveryLocationDelete/deliveryLocationDelete.route';
import { deliveryLocationEditRoute } from '../../view/DeliveryLocationEdit/DeliveryLocationEdit.route';
import { driverRoute } from '../../view/Driver/driver.route';
import { driverAddRoute } from '../../view/DriverAdd/DriverAdd.route';
import { driverAddBulkRoute } from '../../view/DriverAddBulk/driverAddBulk.route';
import { driverDeactivateRoute } from '../../view/DriverDeactivate/driverDeactivate.route';
import { driverDetailRoute } from '../../view/DriverDetail/DriverDetail.route';
import { drivingContestRoute } from '../../view/DrivingContest/drivingContest.route';
import { drivingContestRegisterRoute } from '../../view/DrivingContestRegister/drivingContestRegister.route';
import { drivingContestRegisterConfirmationRoute } from '../../view/DrivingContestRegisterConfirmation/DrivingContestRegisterConfirmation.route';
import { drivingContestRemoveRoute } from '../../view/DrivingContestRemove/drivingContestRemove.route';
import { haulingJORoute } from '../../view/HaulingJO/HaulingJO.route';
import { haulingJobOrderCompleteRoute } from '../../view/HaulingJobOrderComplete/HaulingJobOrderComplete.route';
import { haulingJobOrderCompleteAltRoute } from '../../view/HaulingJobOrderComplete/HaulingJobOrderCompleteAlt.route';
import { haulingJobOrderCreateRoute } from '../../view/HaulingJobOrderCreate/HaulingJobOrderCreate.route';
import { haulingJobOrderCreateAltRoute } from '../../view/HaulingJobOrderCreate/HaulingJobOrderCreateAlt.route';
import { haulingJobOrderDetailRoute } from '../../view/HaulingJobOrderDetail/HaulingJobOrderDetail.route';
import { haulingJobOrderDetailAltRoute } from '../../view/HaulingJobOrderDetail/HaulingJobOrderDetailAlt.route';
import { haulingJobOrderUpdateRoute } from '../../view/HaulingJobOrderUpdate/HaulingJobOrderUpdate.route';
import { haulingJobOrderUpdateAltRoute } from '../../view/HaulingJobOrderUpdate/HaulingJobOrderUpdateAlt.route';
import { haulingOrderRoute } from '../../view/HaulingOrder/HaulingOrder.route';
import { haulingOrderCreateRoute } from '../../view/HaulingOrderCreate/HaulingOrderCreate.route';
import { haulingOrderDetailRoute } from '../../view/HaulingOrderDetail/HaulingOrderDetail.route';
import { haulingOrderForceCompleteRoute } from '../../view/HaulingOrderForceComplete/HaulingOrderForceComplete.route';
import { helpCenterRoute } from '../../view/HelpCenter/HelpCenter.route';
import { helpCenterDataManagementDriverRoute } from '../../view/HelpCenter/sections/DataManagement/Driver/HelpCenterDataManagementDriver.route';
import { helpCenterDataManagementRoute } from '../../view/HelpCenter/sections/DataManagement/HelpCenterDataManagement.route';
import { helpCenterDataManagementLocationRoute } from '../../view/HelpCenter/sections/DataManagement/Location/HelpCenterDataManagementLocation.route';
import { helpCenterDataManagementScheduleRoute } from '../../view/HelpCenter/sections/DataManagement/Schedule/HelpCenterDataManagementSchedule.route';
import { helpCenterDriverAppRoute } from '../../view/HelpCenter/sections/DriverApp/HelpCenterDriverApp.route';
import { helpCenterDriverAppAddExpenseRoute } from '../../view/HelpCenter/sections/DriverApp/HelpCenterDriverAppAddExpense/HelpCenterDriverAppAddExpense.route';
import { helpCenterDriverAppCompleteHJORoute } from '../../view/HelpCenter/sections/DriverApp/HelpCenterDriverAppCompleteHJO/HelpCenterDriverAppCompleteHJO.route';
import { helpCenterDriverAppCompleteJORoute } from '../../view/HelpCenter/sections/DriverApp/HelpCenterDriverAppCompleteJO/HelpCenterDriverAppCompleteJO.route';
import { helpCenterDriverAppPerformanceReportRoute } from '../../view/HelpCenter/sections/DriverApp/HelpCenterDriverAppPerformanceReport/HelpCenterDriverAppPerformanceReport.route';
import { helpCenterDriverContestRoute } from '../../view/HelpCenter/sections/DriverContest/HelpCenterDriverContest.route';
import { helpCenterDriverContestRegisterRoute } from '../../view/HelpCenter/sections/DriverContest/HelpCenterDriverContestRegister/HelpCenterDriverContestRegister.route';
import { helpCenterDriverContestRemovalRoute } from '../../view/HelpCenter/sections/DriverContest/HelpCenterDriverContestRemoval/HelpCenterDriverContestRemoval.route';
import { helpCenterGettingStartedRoute } from '../../view/HelpCenter/sections/GettingStarted/HelpCenterGettingStarted.route';
import { helpCenterGettingStartedTerminologyRoute } from '../../view/HelpCenter/sections/GettingStarted/Terminology/HelpCenterGettingStartedTerminology.route';
import { helpCenterOrderManagementHORoute } from '../../view/HelpCenter/sections/OrderManagement/Hauling/HelpCenterOrderManagementHO.route';
import { helpCenterOrderManagementRoute } from '../../view/HelpCenter/sections/OrderManagement/HelpCenterOrderManagement.route';
import { helpCenterOrderManagementJORoute } from '../../view/HelpCenter/sections/OrderManagement/JobOrder/HelpCenterOrderManagementJO.route';
import { helpCenterOrderManagementSORoute } from '../../view/HelpCenter/sections/OrderManagement/ShipperOrder/HelpCenterOrderManagementSO.route';
import { hoLocationAddRoute } from '../../view/HOLocationAdd/HOLocationAdd.route';
import { homeRoute } from '../../view/Home/Home.route';
import { hoShipperAddRoute } from '../../view/HOShipperAdd/HOShipperAdd.route';
import { jobOrderRoute } from '../../view/JobOrder/JobOrder.route';
import { jobOrderCreateRoute } from '../../view/JobOrderCreate/JobOrderCreate.route';
import { jobOrderDeleteBulkRoute } from '../../view/JobOrderDeleteBulk/JobOrderDeleteBulk.route';
import { jobOrderDetailRoute } from '../../view/JobOrderDetail/JobOrderDetail.route';
import { jobOrderDetailExpenseApproveRoute } from '../../view/JobOrderDetailExpenseApprove/JobOrderDetailExpenseApprove.route';
import { jobOrderEditRoute } from '../../view/JobOrderEdit/JobOrderEdit.route';
import { joCreateBulkRoute } from '../../view/JOCreateBulk/joCreateBulk.route';
import { joCreateLocationAddRoute } from '../../view/JOCreateLocationAdd/JOCreateLocationAdd.route';
import { joDriverAddRoute } from '../../view/JODriverAdd/JODriverAdd.route';
import { joEditDraftLocationAddRoute } from '../../view/JOEditDraftLocationAdd/JOEditDraftLocationAdd.route';
import { joeDriverAddRoute } from '../../view/JOEDriverAdd/JOEDriverAdd.route';
import { legalRoute } from '../../view/Legal/Legal.route';
import { notFoundRoute } from '../../view/NotFound/NotFound.route';
import { organizationResetRoute } from '../../view/OrganizationReset/OrganizationReset.route';
import { profileRoute } from '../../view/Profile/Profile.route';
import { reportDrivingBehaviorByJobOrderRoute } from '../../view/Report/DrivingBehavior/page/JobOrder/reportDrivingBehaviorByJobOrder.route';
import { reportDrivingBehaviorByScheduleRoute } from '../../view/Report/DrivingBehavior/page/Schedule/reportDrivingBehaviorBySchedule.route';
import { reportDrivingBehaviorByVehicleRoute } from '../../view/Report/DrivingBehavior/page/Vehicle/reportDrivingBehaviorByVehicle.route';
import { reportRoute } from '../../view/Report/report.route';
import runnerRoute from '../../view/Runner/Runner.route';
import { scheduleRoute } from '../../view/Schedule/Schedule.route';
import { scheduleAddRoute } from '../../view/ScheduleAdd/ScheduleAdd.route';
import { scheduleRemoveRoute } from '../../view/ScheduleRemove/scheduleRemove.route';
import { shipperRoute } from '../../view/Shipper/shipper.route';
import { shipperAddRoute } from '../../view/ShipperAdd/ShipperAdd.route';
import { shipperAddBulkRoute } from '../../view/ShipperAddBulk/ShipperAddBulk.route';
import { shipperDeactivateRoute } from '../../view/ShipperDeactivate/shipperDeactivate.route';
import { shipperEditRoute } from '../../view/ShipperEdit/ShipperEdit.route';
import { shipperOrderRoute } from '../../view/ShipperOrder/ShipperOrder.route';
import { createShipperOrderRoute } from '../../view/ShipperOrderCreate/ShipperOrderCreate.route';
import { shipperOrderDeleteBulkRoute } from '../../view/ShipperOrderDeleteBulk/ShipperOrderDeleteBulk.route';
import { shipperOrderDetailRoute } from '../../view/ShipperOrderDetail/ShipperOrderDetail.route';
import { soCreateBulkRoute } from '../../view/SOCreateBulk/soCreateBulk.route';
import { soDetailDeliveryLocationAddRoute } from '../../view/SODDeliveryLocationAdd/SODDeliveryLocationAdd.route';
import { soDeliveryLocationAddRoute } from '../../view/SODeliveryLocationAdd/SODeliveryLocationAdd.route';
import { soShipperAddRoute } from '../../view/SOShipperAdd/SOShipperAdd.route';
import { trackingRoute } from '../../view/Tracking/Tracking.route';

export type RouteOptions = {
  label: string;
  testID: string;
  icon?: React.ReactNode;
  hasDivider?: boolean;
  hasSub?: RouteConfig[];
  event?: {
    label: string;
  };
};

export type RouteConfig = BreadcrumbsRoute & {
  path: string;
  name: string;
  Component: ReturnType<typeof React.lazy> | undefined;
  NormalComponent?: JSX.Element;
  isPrivate: boolean;
  options?: RouteOptions;
  isLink?: boolean;
  activePath?: string[];
  customNavigation?: () => void;
};

export type DefaultRouteParam = Params<string>;

export const sidebarConfig: RouteConfig[] = [
  homeRoute,
  shipperOrderRoute,
  jobOrderRoute,
  haulingOrderRoute,
  reportRoute,
  shipperRoute,
  driverRoute,
  scheduleRoute,
  deliveryLocationRoute,
  activityTemplateRoute,
  runnerRoute,
  drivingContestRoute,
];

export const sidebarHelpCenterConfig: RouteConfig[] = [
  helpCenterGettingStartedRoute,
  helpCenterOrderManagementRoute,
  helpCenterDataManagementRoute,
  helpCenterDriverContestRoute,
  helpCenterDriverAppRoute,
];

const helpCenterRoutes: RouteConfig[] = [
  helpCenterRoute,
  helpCenterDriverContestRoute,
  helpCenterDriverContestRegisterRoute,
  helpCenterDriverContestRemovalRoute,
  helpCenterDataManagementRoute,
  helpCenterDataManagementScheduleRoute,
  helpCenterDataManagementDriverRoute,
  helpCenterDataManagementLocationRoute,
  helpCenterOrderManagementRoute,
  helpCenterOrderManagementJORoute,
  helpCenterOrderManagementSORoute,
  helpCenterOrderManagementHORoute,
  helpCenterDriverAppRoute,
  helpCenterDriverAppAddExpenseRoute,
  helpCenterDriverAppCompleteHJORoute,
  helpCenterDriverAppCompleteJORoute,
  helpCenterDriverAppPerformanceReportRoute,
  helpCenterGettingStartedRoute,
  helpCenterGettingStartedTerminologyRoute,
];

export const mobileRedirectionRoutePath = homeRoute.path;

export default [
  homeRoute,
  notFoundRoute,
  shipperOrderRoute,
  shipperOrderDeleteBulkRoute,
  soCreateBulkRoute,
  jobOrderRoute,
  joCreateBulkRoute,
  jobOrderCreateRoute,
  jobOrderDetailRoute,
  jobOrderDetailExpenseApproveRoute,
  jobOrderEditRoute,
  jobOrderDeleteBulkRoute,
  createShipperOrderRoute,
  haulingOrderCreateRoute,
  deliveryLocationRoute,
  deliveryLocationAddRoute,
  deliveryLocationDeleteRoute,
  deliveryLocationEditRoute,
  deliveryLocationAddBulkRoute,
  driverAddRoute,
  driverDetailRoute,
  driverDeactivateRoute,
  driverAddBulkRoute,
  shipperDeactivateRoute,
  shipperAddBulkRoute,
  shipperAddRoute,
  shipperEditRoute,
  shipperRoute,
  driverRoute,
  reportRoute,
  activityTemplateRoute,
  activityTemplateAddRoute,
  activityTemplateEditRoute,
  activityTemplateDeleteRoute,
  scheduleRoute,
  scheduleAddRoute,
  scheduleRemoveRoute,
  shipperOrderDetailRoute,
  soDeliveryLocationAddRoute,
  soDetailDeliveryLocationAddRoute,
  legalRoute,
  haulingOrderRoute,
  haulingOrderForceCompleteRoute,
  haulingJORoute,
  haulingOrderDetailRoute,
  soShipperAddRoute,
  joCreateLocationAddRoute,
  joEditDraftLocationAddRoute,
  joDriverAddRoute,
  joeDriverAddRoute,
  profileRoute,
  trackingRoute,
  hoShipperAddRoute,
  hoLocationAddRoute,
  drivingContestRoute,
  drivingContestRemoveRoute,
  drivingContestRegisterRoute,
  drivingContestRegisterConfirmationRoute,
  completeActivityRoute,
  completeActivityConfirmRoute,
  haulingJobOrderDetailRoute,
  haulingJobOrderDetailAltRoute,
  haulingJobOrderCompleteRoute,
  haulingJobOrderCompleteAltRoute,
  haulingJobOrderUpdateRoute,
  haulingJobOrderUpdateAltRoute,
  haulingJobOrderCreateRoute,
  haulingJobOrderCreateAltRoute,
  organizationResetRoute,
  reportDrivingBehaviorByJobOrderRoute,
  reportDrivingBehaviorByVehicleRoute,
  reportDrivingBehaviorByScheduleRoute,
  ...helpCenterRoutes,
] as RouteConfig[];
