import { useMemo, useState } from 'react';
import { AllCheckboxState } from '../constant';

export type CheckedItem<T> = T & { id: string };

export default function useTableCheckbox<Entity>(
  listData: CheckedItem<Entity>[] | undefined,
) {
  const [checkedItems, setCheckedItems] = useState<CheckedItem<Entity>[]>([]);

  const allCheckbox = useMemo(() => {
    if (!listData) return AllCheckboxState.UNCHECKED;

    const intersected = listData.filter((item) =>
      checkedItems.find((checkedItem) => checkedItem.id === item.id),
    );

    if (intersected.length === 0) return AllCheckboxState.UNCHECKED;
    if (intersected.length === listData.length) return AllCheckboxState.CHECKED;

    return AllCheckboxState.INDETERMINATE;
  }, [checkedItems, listData]);

  return {
    checkedItems,
    setCheckedItems,
    allCheckbox,
  };
}
