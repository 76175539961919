import React from 'react';
import { Icon } from '../../component/atom';
import { RouteConfig } from '../../config/router/router.config';

export type ActivityTemplateEditRouteParam = {
  id: string;
};

export const activityTemplateEditRoute: RouteConfig = {
  name: 'Edit Activity Template',
  path: '/activity-template/edit/:id',
  Component: React.lazy(() => import('./ActivityTemplateEdit.view')),
  isPrivate: true,
  breadcrumb: 'Edit Activity Template',
  props: {
    isUnclickable: true,
  },
  options: {
    icon: <Icon.NavActivityTemplate />,
    label: 'Activity Template',
    testID: 'MenuActivityTemplateEdit',
  },
};
