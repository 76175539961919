import React from 'react';
import { ExtraStyle } from '../../../Type.component';

type Props = React.SVGProps<SVGSVGElement> & {
  iconStyle?: ExtraStyle;
};

export default function Drag({
  width = 6,
  height = 10,
  fill = 'none',
  iconStyle,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      css={iconStyle}
    >
      <rect width={2} height={2} fill="#C9C9C9" rx={1} />
      <rect width={2} height={2} x={4} fill="#C9C9C9" rx={1} />
      <rect width={2} height={2} y={4} fill="#C9C9C9" rx={1} />
      <rect width={2} height={2} x={4} y={4} fill="#C9C9C9" rx={1} />
      <rect width={2} height={2} y={8} fill="#C9C9C9" rx={1} />
      <rect width={2} height={2} x={4} y={8} fill="#C9C9C9" rx={1} />
    </svg>
  );
}
