import React, { Fragment } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import tw, { styled, theme } from 'twin.macro';
import { ActivityTemplateItem } from '../../../model/ActivityTemplate.model';
import { Icon, IconButton, LoadingIndicator, Text } from '../../atom';
import { Outlined } from '../../atom/Button/Button.atom';
import { EditV2 } from '../../atom/Icon/Icon.atom';
import { CustomTableRowWithPopover } from '../../molecule';
import ActivityTemplateDraggableItem from '../ActivityTemplateDraggableItem/ActivityTemplateDraggableItem.organism';

const IconWrapper = tw.div`flex items-center justify-center mx-2 mb-2`;
const SectionLocationHeader = tw.div`flex items-center justify-between bg-beige-bg py-2 px-4 border-beige-lines border rounded-t-md`;
const SectionLocation = tw.div`mb-2 py-1 px-2 max-w-full rounded-t-md border border-b-2 border-beige-lines border-b-grey-two bg-white`;
const SectionLocationContainer = styled.div(
  ({ isEditing }: { isEditing?: boolean }) => [
    tw`flex flex-wrap items-center p-4 border border-beige-lines rounded-b-md bg-white`,
    isEditing && tw`rounded-b-none`,
  ],
);
const SectionLocationFooter = tw.div`flex items-center justify-between py-2 px-4 border border-beige-lines border-t-0 bg-white rounded-b-md gap-4 whitespace-pre-wrap`;
const SectionLocationFooterButton = tw(Outlined)`w-[72px] h-8 text-[12px]`;

type Props = {
  items: ActivityTemplateItem[];
  isLoading: boolean;
  isEditing: boolean;
  isSaveDisabled: boolean;
  onEditToggle: () => void;
  onSave: () => void;
  onCancel: () => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  translate: (key: string) => string;
};

export const ActivityTemplateLocationList: React.FC<Props> = ({
  items,
  isEditing,
  isLoading,
  isSaveDisabled,
  onEditToggle,
  onSave,
  onCancel,
  moveItem,
  translate,
}) => (
  <>
    <SectionLocationHeader>
      <Text.BodyFourteen tw="text-text-color-text-secondary">
        {translate('Location Details')}
      </Text.BodyFourteen>
      {isEditing ? (
        <Text.ButtonTwelve tw="text-text-color-text-secondary">
          {translate('Edit Mode')}
        </Text.ButtonTwelve>
      ) : (
        <IconButton squared onClick={onEditToggle} tw="gap-2 text-orange">
          <EditV2 width={12} />
          <Text.ButtonTwelve>{translate('Change Order')}</Text.ButtonTwelve>
        </IconButton>
      )}
    </SectionLocationHeader>

    <DndProvider backend={HTML5Backend}>
      <SectionLocationContainer isEditing={isEditing}>
        {items.map((item, idx) => (
          <Fragment key={item.id}>
            {idx !== 0 && (
              <IconWrapper>
                <Icon.ChevronSharp height={18} width={18} strokeWidth={2} />
              </IconWrapper>
            )}
            {isEditing ? (
              <ActivityTemplateDraggableItem
                id={item.id}
                index={idx}
                item={item}
                moveItem={moveItem}
              />
            ) : (
              <SectionLocation>
                <CustomTableRowWithPopover
                  primaryLabel={`${idx + 1}. ${item.location.name}`}
                  tooltipPlacement="top"
                  customTooltip={
                    <Text.Paragraph tw="whitespace-normal break-words max-w-xl">
                      {item.location.address}
                    </Text.Paragraph>
                  }
                />
              </SectionLocation>
            )}
          </Fragment>
        ))}
      </SectionLocationContainer>
    </DndProvider>

    {isEditing && (
      <SectionLocationFooter>
        <Text.Paragraph tw="text-grey-three">
          {translate(
            'Drag and drop the location cards from the initial order to a different order as per your requirements.',
          )}
        </Text.Paragraph>
        <div tw="flex gap-3">
          <SectionLocationFooterButton disabled={isLoading} onClick={onCancel}>
            {translate('Cancel')}
          </SectionLocationFooterButton>
          <SectionLocationFooterButton
            css={[
              !isSaveDisabled && tw`bg-orange text-white hover:text-orange`,
              isLoading && tw`w-[100px]`,
            ]}
            disabled={isSaveDisabled}
            onClick={onSave}
          >
            <div tw="flex items-center gap-2">
              {isLoading && (
                <LoadingIndicator color={theme`colors.grey.four`} size="mini" />
              )}
              <Text.ButtonTwelve>{translate('Save')}</Text.ButtonTwelve>
            </div>
          </SectionLocationFooterButton>
        </div>
      </SectionLocationFooter>
    )}
  </>
);
