import React from 'react';
import {
  DefaultRouteParam,
  RouteConfig,
} from '../../../../../config/router/router.config';

export type HelpCenterDriverAppGoogleMapsRedirectionRouteParam =
  DefaultRouteParam;

export const helpCenterDriverAppGoogleMapsRedirectionRoute: RouteConfig = {
  name: 'Help Center Driver App Google Maps Redirection',
  Component: React.lazy(
    () => import('./HelpCenterDriverAppGoogleMapsRedirection.view'),
  ),
  path: '/help-center/driver-app/map-redirection',
  isPrivate: false,
  breadcrumb: 'Help Center Driver App Google Maps Redirection',
  options: {
    label: 'Pengalihan Google Maps',
    testID: 'MenuHelpCenterDriverAppGoogleMapsRedirection',
  },
};
