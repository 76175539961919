import * as yup from 'yup';
import { SOActivityType } from '../../constant';
import { UseTranslator } from '../../hook/useTranslator.hook';
import {
  ActivityTemplate,
  ActivityTemplateFormValues,
} from '../../model/ActivityTemplate.model';

export const activityTemplateInitialValues: ActivityTemplateFormValues = {
  name: '',
  description: '',
  shipperId: '',
  items: [
    {
      type: SOActivityType.STAND_BY,
      locationId: '',
      locationType: undefined,
      index: 0,
    },
  ],
};

export const activityTemplateValidationSchema = (translator: UseTranslator) =>
  yup.object().shape({
    name: yup
      .string()
      .required(translator.translate('Activity template name is required.'))
      .min(
        3,
        translator.translate(
          'Activity template name must be atleast 3 characters.',
        ),
      )
      .max(
        100,
        translator.translate(
          'Activity template name must be atmost 100 characters.',
        ),
      ),
    description: yup
      .string()
      .max(
        300,
        translator.translate('Description must be atmost 300 characters.'),
      )
      .optional(),
    shipperId: yup.string().optional(),
    items: yup.array().of(
      yup.object().shape({
        type: yup
          .string()
          .required(translator.translate('Activity Type is required.')),
        locationId: yup
          .string()
          .required(translator.translate('Location is required.')),
        locationType: yup.string().optional(),
        index: yup.string().defined(),
      }),
    ),
  });

export function transformActivityDetailToFormValues(
  activityTemplate?: ActivityTemplate,
): ActivityTemplateFormValues {
  if (!activityTemplate) return activityTemplateInitialValues;

  return {
    ...activityTemplate,
    ...(activityTemplate.shipper && {
      shipperId: activityTemplate.shipper?.id ?? '',
      shipperOption: {
        label: activityTemplate.shipper?.name ?? '',
        value: activityTemplate.shipper?.id ?? '',
      },
    }),
    items: activityTemplate.items.map((item) => ({
      index: item.index,
      type: item.type,
      locationId: item.location.id,
      locationType: item.location.type,
      locationOption: {
        label: item.location.name,
        value: item.location.id,
      },
    })),
  };
}
