import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarTheme } from '../component/molecule/Snackbar/Snackbar.molecule';
import {
  ActivityTemplate,
  ActivityTemplateItem,
  ActivityTemplateUpdateResponse,
} from '../model/ActivityTemplate.model';
import { logEvent } from '../service/analytic/analytic.service';
import { ApiErrorResponse } from '../service/api.endpoint';
import api from '../service/api.service';
import { snackbarAction } from '../store/snackbar.store';
import { transformActivityDetailToFormValues } from '../util/activityTemplate/add.util';
import { errorCodeToLabel } from '../util/error.util';
import useVerifyAuth from '../view/Wrapper/hooks/useVerifyAuth.hook';
import useTranslator from './useTranslator.hook';

type Props = {
  activityTemplate: ActivityTemplate;
  onEditSuccess?: (activityTemplateItems: ActivityTemplateItem[]) => void;
};

export const useActivityTemplateExpandedSection = ({
  activityTemplate,
  onEditSuccess,
}: Props) => {
  const { handleVerifyAuth } = useVerifyAuth();
  const dispatch = useDispatch();
  const translator = useTranslator();

  const initialItems = useMemo(
    () => activityTemplate.items,
    [activityTemplate.items],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [items, setItems] = useState(sortBy(initialItems, ['index']));

  const [updateAT, updateATResponse] = api.useUpdateActivityTemplateMutation();

  const formItem = useMemo(
    () => transformActivityDetailToFormValues({ ...activityTemplate, items }),
    [activityTemplate, items],
  );

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      const draggedItem = newItems[dragIndex];

      // Remove dragged item
      newItems.splice(dragIndex, 1);
      // Insert at new position
      newItems.splice(hoverIndex, 0, draggedItem);

      // Update indices
      return newItems.map((item, index) => ({
        ...item,
        index,
      }));
    });
  }, []);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setItems(sortBy(initialItems, ['index']));
  }, [initialItems]);

  const handleError = (error: FetchBaseQueryError | SerializedError) => {
    if (!error) return;

    const data = (error as FetchBaseQueryError).data as ApiErrorResponse;
    dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: translator.translate(errorCodeToLabel(data.error.code)),
      }),
    );
    updateATResponse.reset();
  };

  const handleSuccess = ({
    request,
    response,
  }: {
    request: ActivityTemplateItem[];
    response: ActivityTemplateUpdateResponse;
  }) => {
    if (!response || (response && !response.ok)) return;
    onEditSuccess?.(request);
    setIsEditing(false);
    dispatch(
      snackbarAction.show({
        message: translator.translate('Activity template successfully updated'),
      }),
    );
  };

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);
      logEvent('ActivityTemplateUpdate:Submit', 'ActivityTemplateUpdate');

      const authRes = await handleVerifyAuth();

      if (!authRes) return;

      const response = await updateAT({
        ...formItem,
        id: activityTemplate.id,
        shipperId: formItem.shipperId ?? null,
        description: formItem.description ?? null,
      }).unwrap();

      handleSuccess({ request: items, response });
    } catch (error) {
      handleError(error as FetchBaseQueryError);
    } finally {
      setIsLoading(false);
    }
  }, [
    formItem,
    activityTemplate,
    items,
    activityTemplate.id,
    handleError,
    handleVerifyAuth,
    updateAT,
    handleSuccess,
  ]);

  const handleToggleEdit = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const isSaveDisabled = useMemo(
    () => isLoading || isEqual(initialItems, items),
    [initialItems, items, isLoading],
  );

  return {
    isEditing,
    setIsEditing,
    items,
    moveItem,
    handleCancel,
    handleSave,
    handleToggleEdit,
    isSaveDisabled,
    isLoading,
  };
};
