import React from 'react';
import tw from 'twin.macro';
import { useActivityTemplateExpandedSection } from '../../../hook/useActivityTemplateExpandedSection.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import {
  ActivityTemplate,
  ActivityTemplateItem,
} from '../../../model/ActivityTemplate.model';
import { Text } from '../../atom';
import { ActivityTemplateLocationList } from '../ActivityTemplateLocationList/ActivityTemplateLocationList.organism';

// #region STYLED
const Container = tw.td`flex flex-col space-y-3 pl-[100px]`;
const Section = tw.div`flex flex-col`;
// #endregion

// #region INTERFACES
type Props = {
  expandedData: ActivityTemplate;
  onEditSuccess?: (activityTemplateItems: ActivityTemplateItem[]) => void;
  translate: UseTranslator['translate'];
};

export default function ActivityTemplateExpandedSection({
  expandedData,
  onEditSuccess,
  translate,
}: Props) {
  const {
    isEditing,
    isSaveDisabled,
    items,
    moveItem,
    handleCancel,
    handleSave,
    handleToggleEdit,
    isLoading,
  } = useActivityTemplateExpandedSection({
    activityTemplate: expandedData,
    onEditSuccess,
  });

  return (
    <Container>
      <Section>
        <Text.Paragraph tw="text-grey-three">
          {translate('Activity Template Type')}
        </Text.Paragraph>
        <Text.Paragraph tw="whitespace-normal break-words">
          {expandedData.shipper
            ? `${translate('Shipper')}: ${expandedData.shipper.name}`
            : translate('Public')}
        </Text.Paragraph>
      </Section>

      <Section>
        <Text.Paragraph tw="text-grey-three">
          {translate('Description')}
        </Text.Paragraph>
        <Text.Paragraph tw="whitespace-normal break-words">
          {expandedData.description ?? '-'}
        </Text.Paragraph>
      </Section>

      <Section>
        <ActivityTemplateLocationList
          isLoading={isLoading}
          items={items}
          isEditing={isEditing}
          isSaveDisabled={isSaveDisabled}
          onEditToggle={handleToggleEdit}
          onSave={handleSave}
          onCancel={handleCancel}
          moveItem={moveItem}
          translate={translate}
        />
      </Section>
    </Container>
  );
}
